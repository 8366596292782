<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card class="gradient-grey-button-background">
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.name" label="שם" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.phone" label="טלפון"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.email" label="אימייל"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="formTitle === 'הוסף משתמש חדש'">
                                <v-text-field v-model="itemEdited.password" label="סיסמה"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="formTitle === 'הוסף משתמש חדש' && role == 'admin'">
                                <v-select :items="roleList" v-model="itemEdited.role" label="קבוצה"
                                    :item-text="'hebrewName'" :item-value="'name'" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" v-else-if="formTitle !== 'הוסף משתמש חדש' && role === 'admin'">
                                <v-select :items="roleList" v-model="itemEdited.role" disabled label="קבוצה"
                                    :item-text="'hebrewName'" :item-value="'name'" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="permissionList" v-model="itemEdited.permission" label="הרשאות"
                                    :item-text="'hebrewName'" :item-value="'name'" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.special_page" label="דף נוסף"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="formTitle !== 'הוסף משתמש חדש' && itemEdited.role === 'user'">
                                <v-text-field v-model="itemEdited.user_data_name" label="מסד נתונים"
                                    disabled></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6"
                                v-else-if="itemEdited.role === 'user' && (itemEdited.permission === 'view' || itemEdited.permission === 'basicEdit')">
                                <v-select :items="databases" v-model="itemEdited.user_data_name" label="מסד נתונים"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" v-else-if="itemEdited.role === 'user'">
                                <v-text-field v-model="itemEdited.user_data_name" label="מסד נתונים"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" v-if="itemEdited.role === 'user'">
                                <v-text-field v-model="itemEdited.external_system_number"
                                    label="מספר מערכת תוכן/מרכזייה"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="$store.state.colorPrimary" text @click="openMode = false">
                    ביטול
                </v-btn>
                <v-btn :color="$store.state.colorPrimary" text
                    @click="formTitle === 'ערוך משתמש' ? saveEditedUser() : saveNewUser()">
                    שמור
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-snackbar v-model="snackbar" :timeout="4000">
            {{ snacbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn :color="snackbarColorBt" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service';

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        databases: Array,
        value: { type: Boolean, default: false },

    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {},
        permissionList: [{ hebrewName: "הכל", name: "edit" }, { hebrewName: "עריכה בסיסית", name: "basicEdit" }, { hebrewName: "צפייה בלבד", name: "view" }],
        roleList: [{ hebrewName: "משתמש", name: "user" }, { hebrewName: "סוכן", name: "agent" }, { hebrewName: "מנהל", name: "admin" }],
        oldDataName: "",
        role: "",
    }),

    methods: {
        async saveNewUser() {
            if (this.$refs.form.validate()) {

                const datesJSON = JSON.stringify(this.itemEdited);
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/admin/create_user";
                    const res = await fetch(api, ApiServices.requestOptions("POST", datesJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.$emit("userAdded", "", " :שגיאה בהוספת משתמש" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.itemEdited.id = jsonObject.id
                        this.itemEdited.status = 1
                        this.$emit("userAdded", this.itemEdited, "המשתמש נוסף בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved user: " + error, "red");
                    this.$emit("userAdded", "", "Error saved user: " + error, "red");
                }
            }
        },
        async saveEditedUser() {
            if (this.$refs.form.validate()) {
                this.itemEdited.oldDataName = this.oldDataName;
                const datesJSON = JSON.stringify(this.itemEdited);
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/admin/update_user";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", datesJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.$emit("userEdited", "", " :שגיאה בעריכת משתמש" + jsonObject.message, "red");
                        this.openMode = false;
                    } else if (res.status === 200) {
                        let date = new Date().toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' });
                        this.itemEdited.modified_at = date;
                        this.$emit("userEdited", this.itemEdited, "המשתמש עודכן בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved list: " + error, "red");
                    this.$emit("userEdited", "", "Error edit date: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        validateNumericInput(event) {
            const charCode = event.charCode;
            if (charCode < 48 || charCode > 57) {
                event.preventDefault();
            }
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.role = Auth.getUserRole();
        if (this.role === "agent") {
            this.itemEdited.role = "user";
        }

        if (this.formTitle === "ערוך משתמש") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
            this.oldDataName = this.itemEdited.user_data_name;
        }
    },
}
</script>
<style></style>